import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as React from 'react'
import { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { Provider as SupabaseProvider } from 'react-supabase'
import { SupabaseAuthProvider } from 'src/providers'
import 'src/utils/i18n'
import { supabaseClient } from 'src/utils/supabase'
import theme from '../../src/theme'

type Props = {
  children: ReactNode | undefined
}

export default function TopLayout({ children }: Props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <SupabaseProvider value={supabaseClient}>
          <SupabaseAuthProvider>{children}</SupabaseAuthProvider>
        </SupabaseProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}

TopLayout.propTypes = {
  children: PropTypes.node
}
