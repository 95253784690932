exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-list-tsx": () => import("./../../../src/pages/my-list.tsx" /* webpackChunkName: "component---src-pages-my-list-tsx" */),
  "component---src-pages-my-list-without-authentication-tsx": () => import("./../../../src/pages/my-list-without-authentication.tsx" /* webpackChunkName: "component---src-pages-my-list-without-authentication-tsx" */),
  "component---src-pages-my-profile-tsx": () => import("./../../../src/pages/my-profile.tsx" /* webpackChunkName: "component---src-pages-my-profile-tsx" */),
  "component---src-pages-professors-index-tsx": () => import("./../../../src/pages/professors/index.tsx" /* webpackChunkName: "component---src-pages-professors-index-tsx" */),
  "component---src-pages-professors-search-tsx": () => import("./../../../src/pages/professors/search.tsx" /* webpackChunkName: "component---src-pages-professors-search-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-sign-in-with-magick-link-tsx": () => import("./../../../src/pages/sign-in-with-magick-link.tsx" /* webpackChunkName: "component---src-pages-sign-in-with-magick-link-tsx" */),
  "component---src-pages-sign-in-with-password-tsx": () => import("./../../../src/pages/sign-in-with-password.tsx" /* webpackChunkName: "component---src-pages-sign-in-with-password-tsx" */),
  "component---src-pages-tasks-tsx": () => import("./../../../src/pages/tasks.tsx" /* webpackChunkName: "component---src-pages-tasks-tsx" */)
}

