/**
 * lib/supabaseClient.js
 * Helper to initialize the Supabase client.
 */

import { createClient } from '@supabase/supabase-js'

export const supabaseClient = createClient(
  String(process.env.GATSBY_SUPABASE_URL),
  String(process.env.GATSBY_SUPABASE_KEY)
)
